import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/auth'
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey:  `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: "cvcorp-dashboard.firebaseapp.com",
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
  projectId: "cvcorp-dashboard",
  storageBucket: "cvcorp-dashboard.appspot.com",
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_API_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;

export const storage = firebase.storage().ref();