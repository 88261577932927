import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight, Instagram, Facebook, Linkedin } from 'react-feather';
import AOS from 'aos';
import "aos/dist/aos.css";

import { Link } from 'react-router-dom';
import lightLogo from '../../assets/logo/cvcorp-logo.png';
import darkLogo from '../../assets/logo/cvcorp-dark.png';
import './home.css';
// import { AppConsumer } from '../../context/AppContext';

const Home = () => {

    const [opacityFactor, setOpacityFactor] = useState(1);
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        AOS.init({
            duration: 600,
            offset: -10,
            once: true,
        });
    }, []);

    const onScroll = (x) => {
        let individualHeight = Math.round(x.target.scrollHeight / 6);
        let currentPosition = Math.round(x.target.scrollTop);
        if (Math.abs(currentPosition - individualHeight * 5) <= 70) {
            setTheme('dark');
        } else {
            setTheme('light')
        }
        let opacityFactorTemp = Math.abs((currentPosition % individualHeight) - (individualHeight / 2)) / (individualHeight / 2)
        setOpacityFactor(opacityFactorTemp);
    }

    return (
        <React.Fragment>
            <div className={`divCenter w-full fixed z-10`}>
                <img id="mainLogo" alt={'cvcorp Icon'} src={theme === 'dark' ? darkLogo : lightLogo} className={`lg:h-28 h-20 sm:hidden block`} />
            </div>
            <div onScroll={(x) => onScroll(x)
                // console.log(x.target.scrollHeight, Math.round(x.target.scrollTop), x.target.offsetHeight, x.target.clientHeight, x.target.onscroll)
            } className="scrollSnap">
                <section className="relative resumeBg">
                    <div className="divCenter flex-col px-4 centeredAb w-full" style={{ opacity: opacityFactor }}>
                        <h2 className="text-center font-normal flex lg:flex-row flex-col text-md lg:text-xl" style={{ letterSpacing: '0.5em' }}><span className="lg:mr-4">YOUR CAREER</span><span></span>PARTNER</h2>
                        {/* <h1 className="text-center mt-6 lg:mt-10 font-medium lg:text-3xl text-lg md:w-2/3">We Provide <span className="text-red-600" style={{ textShadow: '2px 2px #ffff' }}>Comprehensive Job Training</span> and ensure wide <span className="text-red-600" style={{ textShadow: '2px 2px #ffff' }}>Placement Opportunities</span> for B.Tech Freshers (all branches) interested to join the IT industry.</h1> */}
                        <h1 className="text-center mt-6 lg:mt-10 font-medium lg:text-2xl text-lg md:w-2/3">We Provide <span className="text-red-600" style={{ textShadow: '2px 2px #ffff' }}>Comprehensive Job Training</span> and ensure wide <span className="text-red-600" style={{ textShadow: '2px 2px #ffff' }}>Placement Opportunities</span> for B.Tech Freshers (all branches) interested to join the IT industry.</h1>
                        <h2 className="text-center font-normal mt-8 lg:mt-20 md:mt-12 lg:flex-row flex-col text-sm lg:text-xl" style={{ letterSpacing: '0.1em' }}>Succeed in your job search with the most trusted Career partner for B.Tech freshers</h2>
                    </div>
                    <div className="animate-bounce absolute w-full text-2xl divCenter bottom-20">
                        <ChevronDown size="34" onClick={()=> window.scrollBy(0, 1000)} />
                    </div>
                </section>
                <section className="relative about">
                    <div className="divCenter centeredAb w-full flex-col px-4" style={{ opacity: opacityFactor }}>
                        <h2 className="text-center font-medium flex lg:flex-row flex-col text-xl lg:text-2xl" style={{ letterSpacing: '0.3em' }}>About Us</h2>
                        <h2 className="text-center mt-6 lg:mt-8 font-normal text-md lg:text-xl" style={{ letterSpacing: '0.1em' }}><span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>400+</span> Colleges | <span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>1800+</span> Placements | <span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>150+</span> Companies</h2>
                        <p className="text-center mt-2 lg:mt-4 font-medium lg:text-2xl text-lg md:w-2/3 tracking-wider">CVCORP is one of the most <span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>trusted Career Partner</span> for <span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>B.Tech Freshers</span> with excellent Training and hundreds of Success Stories for the last 8 years across AP & Telangana.</p>
                        <Link to="/about"><button className="neoButton flex mt-8">About Us <ChevronRight /></button></Link>
                    </div>
                </section>
                <section className="relative course overflow-y-scroll">
                    <div className="divCenter centeredAb w-full flex-col px-4" style={{ opacity: opacityFactor }}>
                        <h2 className="text-left font-medium flex lg:flex-row flex-col text-xl lg:text-2xl" style={{ letterSpacing: '0.3em' }}>Course</h2>
                        <h2 className="text-left mt-4 lg:mt-8 font-normal flex lg:flex-row flex-col text-md lg:text-xl" style={{ letterSpacing: '0.1em' }}>(Course Duration: 6-7 months)</h2>
                        <h2 className="text-center mt-2 lg:mt-4 font-medium lg:text-2xl text-lg md:w-2/3 tracking-wider text-red-600" style={{ letterSpacing: '0.3em' }}>We train students on?</h2>
                        <ul className="text-left mt-2 lg:mt-4 font-medium lg:text-xl text-lg tracking-tighter" >
                            <li style={{ margin: '2px 0px' }}>Aptitude, Problem Solving & Logical skills</li>
                            <li style={{ margin: '2px 0px' }}>Communication Skills</li>
                            <li style={{ margin: '2px 0px' }}>Programming in Java</li>
                            <li style={{ margin: '2px 0px' }}>Core Java Concepts – Pre OOPS, OOPS & Multi-threading and Collections</li>
                            <li style={{ margin: '2px 0px' }}>SQL & Web Technologies</li>
                            <li style={{ margin: '2px 0px' }}>Advance Java with a real time project</li>
                        </ul>
                        <Link to="/course"><button className="neoButton flex mt-8">Know more <ChevronRight /></button></Link>
                    </div>
                </section>
                <section className="relative faq">
                    <div className="divCenter centeredAb w-full flex-col px-4" style={{ opacity: opacityFactor }}>
                        <h2 className="text-center font-medium flex lg:flex-row flex-col text-xl lg:text-2xl" style={{ letterSpacing: '0.3em' }}>Is it for me?</h2>
                        <p className="text-center mt-10 font-medium lg:text-2xl text-lg md:w-2/3 tracking-wider">Currently, Our CVCORP Job Training Program is open for <span className="text-red-600" style={{ textShadow: '2px 2px #ffff' }}>B.Tech Freshers 2024 & 2025</span> with <span className="text-red-600" style={{ textShadow: '2px 2px #ffff' }}>60% throughout</span> your academics without any backlogs</p>
                    </div>
                </section>
                <section className="relative placements">
                    <div className="divCenter centeredAb w-full flex-col" style={{ opacity: opacityFactor }}>
                        <h2 className="text-center font-medium flex lg:flex-row flex-col text-xl lg:text-2xl" style={{ letterSpacing: '0.3em' }}>Placements</h2>
                        <h2 className="text-center mt-6 lg:mt-8 font-normal text-md lg:text-xl" style={{ letterSpacing: '0.1em' }}><span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>1800+</span> Students Placed in <span className="text-red-600 font-semibold" style={{ textShadow: '2px 2px #ffff' }}>150+</span> Companies Across India</h2>
                        <p className="text-center mt-2 lg:mt-4 font-medium lg:text-2xl text-lg md:w-2/3 tracking-wider">Till date every Student who joined CVCORP and came regularly got placed without fail.</p>
                        <Link to="/placements"><button className="neoButton flex mt-8">Placements <ChevronRight /></button></Link>
                    </div>
                </section>
                <section className="relative bgColor2 contactUs divCenter" >
                    <div className="divCenter centeredAb w-full flex-col px-4" style={{ opacity: opacityFactor }}>
                        <h2 className="text-center font-normal text-base lg:text-xl" style={{ letterSpacing: '0.5em' }}>Contact Us</h2>
                        <h1 className="text-center mt-2 lg:mt-6 font-medium md:text-xl lg:text-xl text-base tracking-widest neoButtonDark"><a href="tel:7288840444">7288840444</a> / <a href="tel:9705198299">9705198299</a></h1>
                        <h2 className="text-center font-normal mt-10 lg:mt-10 text-base lg:text-xl" style={{ letterSpacing: '0.5em' }}>Social Media Handles</h2>
                        <div className="text-center font-medium md:text-xl lg:text-xl text-base divCenter mt-4 lg:mt-6">
                            <a href="http://instagram.com/cvcorp"><button className="neoButtonDark divCenter tracking-widest mx-2"><Instagram size={34} /></button></a>
                            <a href="https://www.linkedin.com/company/cvcorp"><button className="neoButtonDark divCenter tracking-widest mx-2"><Linkedin size={34} /></button></a>
                            <a href="https://www.facebook.com/cvcorpin"><button className="neoButtonDark divCenter tracking-widest mx-2"><Facebook size={34} /></button></a>
                        </div>
                    </div>
                    <p className="absolute mb-4 text-center bottom-0 font-light text-sm md:w-2/3 mt-4 tracking-wider mx-2">© Copyright {new Date().getFullYear()} CVCORP, Inc. All rights reserved. Various trademarks held by their respective owners.</p>
                </section>
            </div>
        </React.Fragment>

    )
}

export default Home;