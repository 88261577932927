import { useEffect } from "react";

const Temp = () => {

    
    
    useEffect(() => {
        
            window.location.href = "https://cvcorp-dashboard.web.app/talent-test";
        
    }, [])
     
    return <div></div>
}

export default Temp;