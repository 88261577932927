import React from 'react';
import LoadingAnimation from './LoadingAnimation/Loading';

const Loading = () => {
    return (
        <div className={`divCenter`}>
            <div className="lg:w-2/3 p-6 my-4 rounded mb-2 mt-2 w-11/12 divCenter">
                <LoadingAnimation />
            </div>
        </div>
    )
}

export default Loading;