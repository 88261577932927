import { useRef, useEffect, useState } from "react";

import "./navbar.css";
import cvcorp from '../../assets/logo/cvcorp-logo.png'
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Navbar = () => {

    const navRef = useRef(null);

    const currentLocation = useLocation();
    const [pathname, setPathname] = useState("/home");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentLocation]);

    useEffect(() => {
        if (navRef !== null) {
            var prevScrollpos = window.pageYOffset;
            window.onscroll = function () {
                var currentScrollPos = window.pageYOffset;
                if (currentScrollPos <= 50) {
                    navRef.current.style.top = "0";
                } else if (prevScrollpos > currentScrollPos) {
                    navRef.current.style.top = "0";
                } else {
                    navRef.current.style.top = "-120px";
                }
                prevScrollpos = currentScrollPos;
            }
        }
    }, [])

    useEffect(() => {
        setPathname(currentLocation.pathname);
    }, [currentLocation])

    return (
        <nav ref={navRef} id="navbar" className="sm:px-0 md:px-40 py-2">
            <img id="mainLogo" alt={'cvcorp Icon'} src={cvcorp} className={`h-24`} />
            <div className="flex navMenu">
                <Link className={`${pathname === '/home' ? 'hoverDummy' : ''}`} to={"/home"}>Home</Link>
                <Link className={`${pathname === '/about' ? 'hoverDummy' : ''}`} to={"/about"}>About</Link>
                <Link className={`${pathname === '/course' ? 'hoverDummy' : ''}`} to={"/course"}>Course</Link>
                <Link className={`${pathname === '/placements' ? 'hoverDummy' : ''}`} to={"/placements"}>Placements</Link>
                <a href="https://cvcorp-dashboard.web.app/talent-test">Talent Test</a>
                <Link className={`${pathname === '/contact' ? 'hoverDummy' : ''}`} to={"/contact"}>Contact Us</Link>
            </div>
        </nav>
    )
}

export default Navbar;