import React, { useEffect } from 'react';
import { Instagram, Facebook, Linkedin, MapPin, Phone } from 'react-feather';
import AOS from 'aos';
import "aos/dist/aos.css";

// import lightLogo from '../../assets/logo/cvcorp-logo.png';
import '../about/about.css';
import './contactUs.css';
import { ImageEle, sharedHeading, miniSharedHeading } from '../shared/SharedElemets';

const Course = () => {

    useEffect(() => {
        AOS.init({
            duration: 600,
            offset: -10,
            once: true,
        });
    }, []);

    return (
        <div className="bgColor1 w-full divCenter flex-col text-center">
            {ImageEle}
            <div data-aos="fade-down" className="w-full z-10 divCenter flex-col px-2">
                {miniSharedHeading("Let's Talk")}
                {sharedHeading("Contact Us")}
            </div>
            <div data-aos="fade-up" className="text-gray-800 text-center font-light divCenter flex-col py-14 w-full">
                <p className="lg:text-5xl text-3xl customFont mb-10">Reach Us</p>
                <MapPin size={30} color={'#d0202d'} />
                <p className="lg:text-2xl text-xl md:w-2/3 mb-10 mt-4 tracking-wider mx-2 divCenter">3rd Floor(Yathi Towers), KPHB Road No.1, HYD</p>
                <Phone size={30} color={'#d0202d'} />
                <p className="lg:text-2xl font-semibold text-xl md:w-2/3 mt-4 tracking-wider mx-2"><a href="tel:7288840444">7288840444</a> / <a href="tel:9705198299">9705198299</a></p>
            </div>
            <div className="bgColor2 w-full divCenter flex-col px-2 py-10">
                <p className="lg:text-4xl text-3xl customFont text-red-600">Our Social</p>
                <div data-aos="fade-up" className="text-center font-medium md:text-xl lg:text-xl text-base flex-col divCenter mt-4 lg:mt-6 w-full">
                    <a className="w-full divCenter" href="http://instagram.com/cvcorp"><button className="neoButtonDark my-4 divCenter tracking-widest mx-2 md:w-1/3 w-5/6"><Instagram size={34} /></button></a>
                    <a className="w-full divCenter" href="https://www.linkedin.com/company/cvcorp"><button className="neoButtonDark my-4 divCenter tracking-widest mx-2 md:w-1/3 w-5/6"><Linkedin size={34} /></button></a>
                    <a className="w-full divCenter" href="https://www.facebook.com/cvcorpin"><button className="neoButtonDark my-4 divCenter tracking-widest mx-2 md:w-1/3 w-5/6"><Facebook size={34} /></button></a>
                </div>
                <p data-aos="fade-up" className="lg:text-xl text-base md:w-2/3 mt-10 tracking-wider mx-2"><span className="text-red-600 lg:text-2xl text-xl">Note:  </span>For New Batch Update follow us on Instagram</p>
            </div>
            <p className="pb-2 pt-4 text-center bottom-0 font-light text-sm tracking-wider px-2 text-white w-full" style={{ backgroundColor: '#d0202d' }}>© Copyright {new Date().getFullYear()} CVCORP, Inc. All rights reserved. Various trademarks held by their respective owners.</p>
        </div>
    )
}

export default Course;